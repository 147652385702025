/* eslint class-methods-use-this: ["off"] */

export default class Node {
  constructor(tag, ancestor) {
    this.proxy = null
    this.ancestor = ancestor
    this.tag = tag
    this.notifiticationCallback = null
  }

  refresh() {
    if (this.ancestor === null) {
      if (this.notifiticationCallback !== null) {
        const val = JSON.parse(JSON.stringify(this.proxy))
        this.notifiticationCallback(this.tag, val)
      }
    } else {
      this.ancestor.refresh()
    }
  }

  bind(obj) {
    const handler = {
      get: (target, property, value, receiver) => {
        const result = Reflect.get(target, property, value, receiver)
        return result
      },
      set: (target, property, value, receiver) => {
        const result = Reflect.set(target, property, value, receiver)
        this.refresh()
        return result
      },
    }

    this.proxy = new Proxy(obj, handler)
    return this.proxy
  }

  watch(callback) {
    this.notifiticationCallback = callback
  }
}
