<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <b-table
        v-if="visibleFields.length !== 0"
        class="accountTableView"
        primary-key="number"
        show-empty
        responsive
        striped
        hover
        empty-text="Não há conta cadastrada."
        :items="accounts"
        :fields="visibleFields"
        :busy="isLoading"
        :sort-by.sync="sortOptions.sortBy"
        :sort-desc.sync="sortOptions.isSortDirDesc"
        tbody-tr-class="accountTableCustomHeight"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner
              variant="info"
              class="align-middle mb-1"
            />
            <br>
            <strong class="pt-2">Carregando ...</strong>
          </div>
        </template>

        <template #cell(id)="field">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'administrator-account-edit', params: { id: field.item.id } }"
              class="font-weight-bold d-block text-nowrap text-right"
            >
              #{{ field.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(name)="field">
          <span class="text-nowrap font-weight-bold">
            {{ field.item.name }}
          </span>
        </template>

        <template #cell(cnpj)="field">
          <span
            v-if="field.item.cnpj"
            class="text-nowrap font-weight-bold"
          >
            {{ field.item.cnpj | VMask('##.###.###/####-##') }}
          </span>
          <span
            v-else
            class="text-nowrap font-weight-bold"
          >
            -
          </span>
        </template>

        <template #cell(profile__name)="field">
          <span
            v-if="field.item.profile_set"
            class="text-nowrap font-weight-bold"
          >
            {{ field.item.profile_set.name }}
          </span>
          <span
            v-else
            class="text-nowrap font-weight-bold"
          >
            -
          </span>
        </template>

        <template #cell(situation)="field">
          <span
            v-if="field.item.situation === 'active'"
            class="text-nowrap font-weight-bold"
          >
            <b-badge
              pill
              class="badgeStyle text-capitalize sizeMin d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': '#049F14'}"
            >
              Ativo
            </b-badge>
          </span>
          <span v-else>
            <b-badge
              pill
              class="badgeStyle text-capitalize sizeMin d-inline-flex align-items-center justify-content-center"
              :style="{'background-color': '#FF7E07'}"
            >
              Inativo
            </b-badge>
          </span>
        </template>

        <template #cell(field_actions)="field">
          <div>
            <b-button
              class="btn-icon rounded-circle"
              variant="flat-info"
              size="md"
              @click="showConfirmOrCancelDelete(field.item)"
            >
              <svg-icon
                type="mdi"
                size="18"
                :path="mdiTrashCanOutline"
              />
            </b-button>
          </div>
        </template>
      </b-table>
      <div
        class="mx-2 mb-2"
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-if="visibleFields.length !== 0"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="filter.page_size"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div>
      <b-button
        id="addAccountBtn"
        v-b-tooltip.hover
        class="btn-icon rounded-circle m-3"
        variant="primary"
        size="md"
        :to="{ name: 'administrator-account-create'}"
        title="Adicionar Conta"
      >
        <svg-icon
          type="mdi"
          :path="mdiPlus"
        />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BTable,
  BPagination,
  VBTooltip,
  BBadge,
  BSpinner,
  BMedia,
  BLink,
} from 'bootstrap-vue'

import {
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import {
  useToast,
} from 'vue-toastification/composition'

import SvgIcon from '@jamescoyle/vue-icon'
import moment from 'moment'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import syslic from '@/syslic'
import SyncLoad from '@/components/utils/syncLoad'
import MetaData from '@/components/utils/metaData'
import debounce from '@/components/utils/debounce'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BBadge,
    BSpinner,
    BMedia,
    BLink,
    SvgIcon,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  emits: ['ready'],

  data() {
    const totalItems = 0
    const currentPage = 1
    const perPage = 10

    const sortOptions = {
      sortBy: 'id',
      isSortDirDesc: false,
    }

    const lastSearch = ''

    const accounts = []

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap accountTableColumnId',
      },
      {
        key: 'name',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap accountTableColumn',
      },
      {
        key: 'cnpj',
        label: 'CNPJ',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap accountTableColumn',
      },
      {
        key: 'profile__name',
        label: 'Plano',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap accountTableColumn',
      },
      {
        key: 'situation',
        label: 'Situação',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap accountTableColumn',
      },
      {
        key: 'field_actions',
        label: '',
        visible: true,
        fixedColumn: false,
        sortable: true,
        thClass: 'text-nowrap accountTableColumnId',
      },
    ]

    const fetchId = ''

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('accounts')

    syncLoad.onReady(() => {
      this.isLoading = false
      this.$emit('ready', true)
    })

    const meta = new MetaData('AccountTable')

    return {
      fetchId,
      totalItems,
      perPage,
      currentPage,
      lastSearch,
      syncLoad,
      accounts,
      tableColumns,
      isLoading,
      sortOptions,
      meta,
    }
  },
  computed: {
    visibleFields() {
      return this.tableColumns.filter(field => field.visible)
    },
  },
  watch: {
    filter: {
      handler: function updateFilter(val) {
        this.currentPage = 1
        if (this.lastSearch !== val.search) {
          this.lastSearch = val.search
        }
        this.fetchData()
      },
      deep: true,
    },
    'sortOptions.sortBy': function updateSortBy() {
      this.currentPage = 1
      this.fetchData()
    },
    'sortOptions.isSortDirDesc': function updateIsSortDirDesc() {
      this.currentPage = 1
      this.fetchData()
    },
    currentPage: function updateCurrentPage() {
      this.fetchData()
    },
  },
  created() {
    this.sortOptions = this.meta.bind(this.sortOptions, 'sortOptions')
    this.fetchData()
  },
  methods: {
    refreshColumns(columns) {
      columns.forEach(element => {
        this.tableColumns.forEach((obj, index) => {
          if (obj.key === element.key) {
            this.tableColumns[index].visible = element.visible
          }
        })
      })
    },
    fetchData() {
      this.isLoading = true
      debounce.call(this.fetch)
    },
    fetch() {
      const fetchPending = Math.random()
      this.fetchId = fetchPending
      const accountFilter = this.filter
      accountFilter.p = this.currentPage

      if (this.sortOptions.isSortDirDesc) {
        accountFilter.ordering = `-${this.sortOptions.sortBy}`
      } else {
        accountFilter.ordering = this.sortOptions.sortBy
      }

      accountFilter.is_master = false

      syslic
        .administrator
        .account
        .fetchAccountList(accountFilter)
        .then(response => {
          let promise = {}

          if (fetchPending === this.fetchId) {
            this.totalItems = response.data.count
            this.accounts = response.data.results

            promise = new Promise(resolve => {
              resolve()
            })
          } else {
            promise = new Promise((resolve, reject) => {
              reject()
            })
          }

          return promise
        })
        .then(() => {
          this.syncLoad.ready('accounts')
        })
        .catch(() => {
          if (fetchPending === this.fetchId) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Falha ao ler os dados dos perfis',
                text: 'Não foi possível ler os dados das contas no servidor, por favor entre em contato com o administrador do sistema.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                timeout: 'false',
              },
            })
            this.syncLoad.ready('accounts')
          }
        })
    },
    onRowSelected(item) {
      router.push({
        name: 'administrator-account-edit',
        params: {
          id: item.id,
        },
      })
    },
    deleteAccount(item) {
      syslic
        .administrator
        .account
        .deleteAccount(item.id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Conta removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.accounts.splice(this.accounts.indexOf(item), 1)
        })
        .catch(error => {
          let textError = 'Não foi possível remover esta conta, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta conta é referenciada, por este motivo não será possível exclui-la.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover conta.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover essa conta?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAccount(item)
          }
        })
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      moment,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
.accountTableView {
  border-radius: .4rem;
}

.accountTableColumn {
  min-width: 6rem;
}

.accountTableColumnId {
  width: 2rem;
}

.accountTableCustomHeight {
  height: 4.5rem;
}

.badgeStyle {
  height: 2.2rem;
  min-width: 10rem;
  font-size: 1rem;
}

#addAccountBtn {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
