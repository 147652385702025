import syslic from '@/syslic'

import Node from './node'

/* eslint class-methods-use-this: ["off"] */

export default class MetaData {
  constructor(key) {
    const profile = syslic.authentication.getProfile()

    this.user = profile.get_uuid
    this.key = key
    this.url = window.location.href
    this.nodes = []
    this.proxies = {}
  }

  build(tag, obj, root) {
    Object.keys(obj).forEach(keyObj => {
      if ((typeof obj[keyObj] === 'object') && (obj[keyObj] !== null)) {
        const tagNode = `metaData-${tag}-${keyObj}`
        const node = new Node(tagNode, root)

        /* eslint no-param-reassign: ["off"] */
        obj[keyObj] = node.bind(obj[keyObj])
        this.nodes.push(node)

        this.build(tagNode, obj[keyObj], root)
      }
    })
  }

  bind(obj, id) {
    if (typeof obj !== 'object') {
      throw new Error('Fail to bind, the variable to watch is not a Object')
    }

    const tag = `metaData-${this.user}-${this.url}-${this.key}-${id}`

    const root = new Node(tag, null)
    this.nodes.push(root)

    const cache = obj
    const stored = this.load(tag)

    if (stored !== null) {
      Object.keys(stored).forEach(element => {
        cache[element] = stored[element]
      })
    }

    const proxy = root.bind(cache)

    this.proxies[tag] = proxy

    this.build(tag, obj, root)

    root.watch(this.observer)

    return proxy
  }

  observer(tag, val) {
    const obj = JSON.stringify(val)
    localStorage.setItem(tag, obj)

    const profile = syslic.authentication.getProfile()

    syslic.metadata.setMetadata(profile.id, tag, val)
  }

  load(tag) {
    let result = null
    const val = localStorage.getItem(tag)

    if (val !== undefined) {
      result = JSON.parse(val)
    }

    return result
  }
}
