class Debounce {
  constructor() {
    this.fetchId = ''
  }

  call(callback, timeout = 1000) {
    const fetchPending = Math.random()
    this.fetchId = fetchPending

    setTimeout(() => {
      if (fetchPending === this.fetchId) {
        callback()
      }
    }, timeout)
  }
}

const debounce = new Debounce()

export default debounce
