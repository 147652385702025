<template>
  <div>
    <div v-show="isLoading">
      <spin-loader />
    </div>
    <div v-show="!isLoading">
      <b-row>
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <account-filter
            :visible-columns="tableColumns"
            @filter-change="updateFilter"
            @columns-change="updateColumns"
            @ready="syncLoad.ready('filter')"
          />
        </b-col>
      </b-row>
      <b-row
        class="justify-content-center mt-1"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <account-table
            ref="refAccountTable"
            :filter="filter"
            :active="true"
            @ready="syncLoad.ready('table')"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import AccountFilter from './AccountFilter.vue'
import AccountTable from './AccountTable.vue'

export default {
  components: {
    BRow,
    BCol,
    AccountTable,
    AccountFilter,
    SpinLoader,
  },
  data() {
    const filter = {
      page_size: 10,
      search: '',
    }

    const isLoading = true
    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('table')

    syncLoad.onReady(() => {
      this.isLoading = false
    })

    const tableColumns = [
      {
        key: 'id',
        label: 'Id',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'name',
        label: 'Empresa',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'cnpj',
        label: 'CNPJ',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'profile__name',
        label: 'Plano',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'situation',
        label: 'Situação',
        visible: true,
        fixedColumn: false,
      },
      {
        key: 'field_actions',
        label: '',
        visible: true,
        fixedColumn: false,
      },
    ]

    return {
      filter,
      isLoading,
      syncLoad,
      tableColumns,
    }
  },
  methods: {
    updateFilter(val) {
      this.filter = val
    },
    updateColumns(val) {
      this.$refs.refAccountTable.refreshColumns(val)
    },
  },
  setup() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
</style>
